

























import Vue from 'vue'
import Component from 'vue-class-component'
import downloadExcel, { ExcelData } from '../../functions/downloadExcel'

@Component
export default class Theme extends Vue {
  country = 'BE'

  testExcelDownload () {
    const data: ExcelData = {
      headers: this.headers,
      data: this.tableData,
      options: {
        name: 'exportTest',
        exportFileName: 'exportTest',
        fileExtension: 'xlsx',
        csvDelimiter: ';'
      }
    }
    downloadExcel(data)
  }

  get headers () {
    const rtn: Array<any> = []
    for (let i = 0; i < 6; i++) {
      rtn.push({ name: `Header ${i + 1}`, totalsRowLabel: 'sum:', filterButton: true })
    }
    return rtn
  }

  get tableData () {
    const rows: Array<Array<string>> = []
    for (let i = 0; i < 20; i++) {
      const row: Array<string> = []
      for (let j = 0; j < 6; j++) {
        row.push(`Row: ${i + 1} Colom: ${j + 1}`)
      }
      rows.push(row)
    }
    return rows
  }
}
